//	eslint-disable-next-line
(function() {
	function sendBeacon(url: string) {
		let sent = false
		try {
			sent = navigator.sendBeacon(url)
		} catch (e) {}
		if (!sent) {
			new Image().src = url
		}
	}
	let is_cached = false
	function isCached(part: any) {
		return !!part && part.indexOf('hit') === 0
	}
	const match = document.cookie.match(
		/ssr-caching="?cache#\s*desc=(\w+)(?:#\s*varnish=(\w+))?(?:#\s*dc,\s*desc=(\w+))?(?:"|;|$)/
	)

	if (match && match.length) {
		const caching = `${match[1]},${match[2] || 'none'}`
		is_cached = isCached(caching)
	}
	if (!match && window.PerformanceServerTiming) {
		const serverTiming = performance.getEntriesByType('navigation')[0].serverTiming
		if (serverTiming && serverTiming.length) {
			const names = ['cache', 'constnish', 'dc']
			const parts: Array<string> = []
			serverTiming.forEach(function(entry) {
				const i = names.indexOf(entry.name)
				if (i > 0) {
					parts[i] = entry.description
				}
			})
			is_cached = isCached(parts[1]) || isCached(parts[2])
		}
	}

	const fedOpsAppName = 'thunderbolt'
	const { rollout } = window.viewerModel
	const { isDACRollout, siteAssetsVersionsRollout } = rollout
	const is_dac_rollout = isDACRollout ? 1 : 0
	const is_sav_rollout = siteAssetsVersionsRollout ? 1 : 0
	const is_rollout =
		window.viewerModel.fleetConfig.code === 0 || window.viewerModel.fleetConfig.code === 1
			? window.viewerModel.fleetConfig.code
			: null
	window.fedops = window.fedops || {}
	window.fedops.apps = window.fedops.apps || {}
	window.fedops.apps[fedOpsAppName] = {
		startLoadTime: window.performance && window.performance.now && window.performance.now(),
	}
	window.fedops.sessionId = window.viewerModel.site.sessionId
	window.fedops.is_cached = is_cached
	const url =
		'//frog.wix.com/bolt-performance?src=72&evid=21&appName=' +
		fedOpsAppName +
		'&is_rollout= ' +
		is_rollout +
		'&is_sav_rollout= ' +
		is_sav_rollout +
		'&is_dac_rollout= ' +
		is_dac_rollout +
		'&dc=' +
		window.viewerModel.site.dc +
		'&is_cached=' +
		is_cached +
		'&msid=' +
		window.viewerModel.site.metaSiteId +
		'&session_id=' +
		window.fedops.sessionId
	sendBeacon(url)
})()
